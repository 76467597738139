<template>
  <div class="font-config">
    <div class="location">
      {{ $t("Welcome") }} > {{ $t("System Configuration") }} >
      <span style="color: #3461ff">{{ $t("font Config") }}</span>
    </div>
    <div class="hd card">
      <div class="handleBtn">
        <el-button type="primary" size="small" @click="handleAdd">
          <i class="el-icon-plus" />
          {{ $t("button.New") }}
        </el-button>
        <el-button type="danger" size="small" @click="handleBatchDel">
          <i class="el-icon-delete" />
          {{ $t("button.Batch Delete") }}
        </el-button>
        <el-button v-if="userData.userType == '201' || userData.userType == '202'" type="primary" size="small" @click="handleFontPermissions">
          {{ $t("button.Font permissions") }}
        </el-button>
      </div>
    </div>
    <div class="bd card">
      <div class="table-header">
        <div>
          <span class="text"
            >{{ $t("fontConfig.Records") }}: {{ pageParams.total }}</span
          >
        </div>
      </div>

      <!-- 表格 -->
      <el-table
        ref="normalTable"
        :data="dataList"
        style="width: 100%"
        @sort-change="handleSort"
        max-height="500"
      >
        <el-table-column align="center" type="selection" fixed>
          <el-table-column align="center" width="55" type="selection">
          </el-table-column>
        </el-table-column>
        <!-- <el-table-column
          prop="languages"
          min-width="130"
          :label="$t('fontConfig.To support languages')"
          header-align="center"
        >
          <el-table-column prop="languages" min-width="130" align="center">
            <template slot="header" slot-scope="scope">
              <el-select
                v-model="pageParams.languages"
                placeholder=""
                size="small"
                clearable
                @change="getData"
              >
                <el-option
                  v-for="(item, i) in languages"
                  :key="i"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </template>
          </el-table-column>
        </el-table-column> -->
        <el-table-column
          prop="fontFileName"
          min-width="200"
          :label="$t('fontConfig.font File Name')"
          header-align="center"
        >
          <el-table-column prop="fontFileName" min-width="200" align="center">
            <template slot="header" slot-scope="scope">
              <el-input
                size="mini"
                v-model="searchParams.fontFileName"
                @input="getData"
                placeholder=""
                clearable
              ></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="fontName"
          min-width="200"
          :label="$t('fontConfig.font Name')"
          header-align="center"
        >
          <el-table-column prop="fontName" min-width="200" align="center">
            <template slot="header" slot-scope="scope">
              <el-input
                size="mini"
                v-model="searchParams.fontName"
                @input="getData"
                placeholder=""
                clearable
              ></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="fontStatus"
          min-width="300"
          :label="$t('fontConfig.status')"
          header-align="center"
        >
          <el-table-column prop="fontStatus" min-width="300" align="center">
            <template slot="header" slot-scope="scope">
              <el-select
                v-model="searchParams.fontStatus"
                placeholder=""
                size="small"
                @change="getData"
                clearable
            >
                <el-option :label="$t('fontConfig.isEffect')" :value="0" />
                <el-option :label="$t('fontConfig.isUpload')" :value="1" />
            </el-select>
            </template>
            <template slot-scope="scope">
                <div style="display: flex; align-items: center;  justify-content: center;"> 
                    <span v-if="scope.row.fontStatus == 0 && !scope.row.isClickStateEdit"> {{$t('fontConfig.isEffect')}} </span>
                    <span v-if="scope.row.fontStatus == 1 && !scope.row.isClickStateEdit"> {{$t('fontConfig.isUpload')}} </span>

                    <span class="table-icon"  v-if="!scope.row.isClickStateEdit && userData.userType == '205'" @click="handleStateEdit(scope.row)">
                        <i class="el-icon-edit-outline" ></i>
                    </span>
                    <el-select
                        v-if="scope.row.isClickStateEdit && userData.userType == '205'"
                        v-model="scope.row.selectedFontStatus"
                        placeholder=""
                        size="small"
                        style="width: 100px;"
                        :ref="'selectIsEffect' + scope.$index" 
                    >
                        <el-option :label="$t('fontConfig.isEffect')" :value="0" />
                        <el-option :label="$t('fontConfig.isUpload')" :value="1" />
                    </el-select>

                    <span class="table-icon" v-if="scope.row.isClickStateEdit&& userData.userType == '205'"  @click="handleStateSave(scope.row)">
                    <i class="el-icon-circle-check"></i>
                    </span>

                    <span class="table-icon" v-if="scope.row.isClickStateEdit&& userData.userType == '205'" @click="handleStateCancel(scope.row)">
                    <i class="el-icon-circle-close"></i>
                    </span>
                </div>
           </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="updateUser"
          min-width="100"
          :label="$t('fontConfig.updateUser')"
          header-align="center"
        >
          <el-table-column prop="updateUser" min-width="100" align="center">
            <template slot="header" slot-scope="scope">
              <el-input
                size="mini"
                v-model="searchParams.updateUser"
                @input="getData"
                placeholder=""
                clearable
              ></el-input>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          prop="agentName"
          min-width="100"
          :label="$t('fontConfig.Upload institution')"
          header-align="center"
        >
          <el-table-column prop="agentName" min-width="150" align="center">
            <template slot="header" slot-scope="scope">
              <el-input
                size="mini"
                v-model="searchParams.agentName"
                @input="getData"
                placeholder=""
                clearable
              ></el-input>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          prop="update_time"
          :label="$t('fontConfig.updateTime')"
          min-width="160"
          header-align="center"
        >
          <el-table-column prop="updateTime" min-width="180" align="center">
            <template slot="header" slot-scope="scope">
              <el-date-picker
                v-model="searchParams.updateTime"
                type="daterange"
                size="mini"
                @change="getData"
                placement="bottom-start"
                value-format="yyyy-MM-dd HH:mm:ss"
                start-placeholder=""
                end-placeholder=""
              />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          fixed="right"
          :label="$t('fontConfig.Action')"
          min-width="200"
          header-align="center"
        >
          <el-table-column align="center" min-width="200">
            <template slot="header"></template>
            <template slot="default" slot-scope="{ row }">
              <div class="operate">
                <!-- admin用户 或者 自己上传的 可以 编辑 删除 -->
                <span v-if="userData.userType == '205' || userData.loginId == row.createUser" class="table-icon" @click="handleDelete(row)">
                  <i class="el-icon-delete"></i>
                </span>
                <span v-if="userData.userType == '205' || userData.loginId == row.createUser" class="table-icon" @click="handleEdit(row)">
                  <i class="el-icon-edit"></i>
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>

      <!-- 页码 -->
      <pagination :tableInfo="pageParams" @changePage="getData" />

      <EditeDialog ref="EditeDialog" @doSubmit="doSubmit"></EditeDialog>

      <CustomerListDilog ref="CustomerListDilog"></CustomerListDilog>

      <!-- 删除弹窗确认 -->
      <deleteConfirmDialog
        ref="deleteConfirmDialog"
        @onDeleteConfirm="deleteConfirm"
      ></deleteConfirmDialog>
    </div>
  </div>
</template>
  <script>
import deleteConfirmDialog from "@/components/delete-confirm-dialog/index";
import pagination from "@/components/pagination";

import EditeDialog from "./modules/EditeDialog";
import CustomerListDilog from "./modules/CustomerListDilog";
import { updateFont, createFont, deleteFont, getFontListByPage } from "@/libs/api/system-config-font";
export default {
  name: "fontConfig",
  components: {
    deleteConfirmDialog,
    pagination,
    EditeDialog,
    CustomerListDilog,
  },
  props: {
    location: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      userData: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {},
      // languages: [],
      searchParams: {
        orders: [],
        // languages: "",
        // remark: "",
        updateTime: [],
        updateUser: "",
        agentName: "",
        fontFileName: "",
        fontName: "",
        fontStatus: "",
      },
      pageParams: {
        current: 1, //当前页
        size: 10, //单页显示最大数
        total: 0,
        pageSizeArr: ["10", "20", "50", "100", "200", "500", "1000"]
      },
      dataList: [],
      deleteData: {},
      isVisibleEditeDialog: false,
      isVisibleCustomerListDialog: false,
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    handleStateCancel(row) {
      row.isClickStateEdit = false;
    },
    handleStateEdit(row) {
      row.selectedFontStatus = row.fontStatus
      row.isClickStateEdit = true;
    },
    async handleStateSave(row) {
      try {
        const params = {
          fontId: row.fontId,
          fontFileName: row.fontFileName,
          fontName: row.fontName,
          fontStatus: row.selectedFontStatus
        }
        let { data } = await updateFont(params);
        if (data.state) {
          this.$message.success(this.$t('fontConfig.save successful'));
          row.isClickStateEdit = false;
          this.getData();
        } else {
          this.$refs.deleteConfirmDialog.close();
          this.$message.error(data.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
       
        
    },
    handleFontPermissions() {
      this.$refs.CustomerListDilog.open();
    },
    //获取数据
    async getData() {
      let userData = JSON.parse(localStorage.getItem("userData"));
      let { fontName, orders, updateUser, agentName, fontFileName, fontStatus, updateTime } = this.searchParams;
      let {current,size } = this.pageParams
      const params = {
        storeId: userData.storeId,
        orders,
        updateUser,
        selectedAgentName: agentName,
        fontFileName,
        fontName,
        fontStatus,
        updateTime: updateTime && updateTime.length >0 ? updateTime[0]+','+updateTime[1]:"",
        current,
        size
      };
      try {
        // let res = await getFontList(params);
        let { data }  = await getFontListByPage(params);
        if (data.state) {
          this.dataList = data.data.records;
          this.dataList.forEach((item) => {
            this.$set(item, 'isClickStateEdit', false)
            this.$set(item, 'selectedFontStatus', item.fontStatus)
          })
          
          this.pageParams.total = data.data.total;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        this.$message.error(err.message);
      }
    },
    // 排序
    handleSort(column) {
      let arr = [];
      if (column.order) {
        let str1 = column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ":" + column.prop;
        arr.push(str1);
      } else {
        arr = [];
      }

      this.searchParams.orders = arr;
      this.getData();
    },
    // 添加
    handleAdd() {
      this.$refs.EditeDialog.open();
    },
    // 获取选中的数量
    selection() {
      return this.$refs.normalTable && this.$refs.normalTable.selection.length;
    },
    // 编辑
    handleEdit(data) {
      this.$refs.EditeDialog.open(data);
    },
    // 批量删除---弹窗确认
    handleBatchDel() {
      let ids = this.$refs.normalTable.selection
        .map((item) => item.fontId)
        .join(",");
      if (!ids) {
        this.$message({
          message: this.$t("fontConfig.selectedNoData"),
          type: "warning",
        });
        return;
      }

      // <!-- admin用户 或者 自己上传的 可以 编辑 删除 --> 
      // userData.userType == '205' || userData.loginId == row.createUser
      let userType = this.userData.userType; //当前登录的用户类型
      let loginId = this.userData.loginId; //当前登录的loginId
      let flay = userType == '205' || this.$refs.normalTable.selection.every((item, index, arr)=> item.createUser == loginId);
      if(!flay) {
        this.$message.warning(this.$t('fontConfig.BatchDelDataErrorTips'));
        return;
      }
      this.deleteData = ids;
      this.$refs.deleteConfirmDialog.open();
    },
    // 删除---弹窗确认
    handleDelete(row) {
      this.deleteData = row.fontId;
      this.$refs.deleteConfirmDialog.open();
    },
    // 删除---api
    async deleteConfirm() {
      let params = {
        ids: this.deleteData,
      };
      try {
        let { data } = await deleteFont(params);
        if (data.state) {
          this.$message({
            message: this.$t("commonMessage.DeleteSucess"),
            type: "success",
          });
          this.$refs.deleteConfirmDialog.close();
          this.getData();
        } else {
          this.$refs.deleteConfirmDialog.close();
          this.$message.error(data.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
    },

    // 表单提交
    async doSubmit(datas) {
     
      let fileName = datas.fileName;
      let fileNameNew = fileName;
      let  {
        formData
      } = datas;
      // let fileNameNew = fileName.split(".")[0];
      // let userData = localStorage.getItem("userData") ?  JSON.parse(localStorage.getItem("userData")) : {};
      try {
        let params = {
          fontFileName: fileNameNew,
          fontName: datas.fontName
        }
        let requestResult = {};
        if(formData.fontId) {//修改
          params.fontId = formData.fontId
          requestResult = await updateFont(params);
        } else {
          params.fileUrl = datas.formData.fileUrl;
          requestResult = await createFont(params);
        }
        let { data }  = requestResult;
        if(data.state) {
          this.getData();
          this.$refs.EditeDialog.dialogVisible = false;
          this.$message.success(this.$t('fontConfig.operationSuccess'));
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        this.$message.error(err.message);
      }
    },
  },
};
</script>
  <style lang="scss" scoped>
.font-config {
  .location {
    margin-bottom: 20px;
  }
  .hd {
    padding: 20px;
  }
  .bd {
    .table-header {
      padding: 20px;
      .text {
        margin-right: 20px;
      }
    }
    .table-icon {
      padding: 6px;
      cursor: pointer;
      font-size: 18px;
      color: #000;
    }
  }

  // 重置样式
  .el-date-editor.el-input__inner {
    width: 160px;
  }
}
.font-config ::v-deep {
  .el-table
    .el-table__fixed-header-wrapper
    .el-table__header
    .is-group
    tr:nth-of-type(2)
    th:nth-of-type(1)
    .cell {
    display: none;
  }
}
</style>
