<template>
  <div class="rule-dialog">
    <el-dialog
      v-model="dialogVisible"
      :title="title"
      :before-close="close"
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <div class="form-box">
        <el-form
          label-width="160px"
          style="width: 100%"
          :model="formData"
          ref="form"
          :rules="rules"
          size="small"
        >
          <el-form-item
            :label="$t('fontConfig.file')"
            prop="fileName"
          >
            <div class="upload-box">
              <el-upload
                  class="upload-demo"
                  action="#"
                  accept=""
                  :before-upload="beforeUpload"
                  :http-request="uploadHttpRequest"
                  :show-file-list="false"
                  :disabled="formData.formData && formData.formData.fontId ? true : false"
                >
                <el-button class="input-box" size="medium" type="primary">
                  <span v-if="!formData.fileName"> {{ $t("fontConfig.SelectUploadFile") }} </span>
                  <span v-else>
                    {{ formData.fileName }}
                  </span>
                </el-button>
              </el-upload>
            </div>
          </el-form-item>
            <!-- <el-col :span="24"> -->
          <el-form-item :label="$t('fontConfig.font Name')" prop="fontName">
            <el-input style="width: 300px;"  v-model="formData.fontName"></el-input>
          </el-form-item>
          <div style="margin-left: 28%;">
            <i style="font-size: 1d8px;" class="el-icon-warning-outline"></i>
            <b style="margin-left: 5px; color: #000;">{{$t("fontConfig.Disclaimers")}}：</b> <span style="font-size: 12px;">{{$t("fontConfig.Disclaimer Content")}}</span>
          </div>
            <!-- </el-col> -->
        </el-form>
      </div>
      <!-- 保存按钮 -->
      <div class="form-btn-section">
        <el-button color="#28C76F" size="small" @click="close">{{
          $t("button.Cancel")
        }}</el-button>
        <el-button
          color="#28C76F"
          size="small"
          type="success"
          @click="onSubmit"
        >
          {{ $t("button.Save") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import $Api from "@/libs/api/system-config-unit";
import {
  uploadFile
} from "@/libs/api/system-config-font";
export default {
  name: "EditeDialog",
  components: {
  },
  props: {},
  data() {
    return {
      rules: {
        fileName: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
        ],
        fontName: [
          {
            required: true,
            message: this.$t("formValidate.required fields"),
            trigger: "blur",
          },
        ]
      },
      formData: {
        fileName: "",
        fontName: ""
      },
      dialogVisible: false,
      title: this.$t("fontConfig.Create Font")
    };
  },
  created() {},
  mounted() {},
  methods: {
    beforeUpload(file) {
      let fileName = file.name;
      let pos = fileName.lastIndexOf(".");
      let lastName = fileName.substring(pos, fileName.length);
      if (lastName.toLowerCase() !== ".ttf") {
        this.$message.error(this.$t('fontConfig.fileTypeTips'));
        return;
      }
       

      if (file) {
        this.formData.fileName = file.name;
        this.formData.fontName = file.name ? file.name.split(".")[0] : '';
        this.$refs['form'].clearValidate();
      }
      // 文件大小限制为10M
      // const fileLimit = file.size / 1024 / 1024 < 10;
      // if (!fileLimit) {
      //   this.$message.error("上传文件大小不超过10M！");
      // }
    },
    async uploadHttpRequest(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("fileType", 9);
      formData.append("storeId", localStorage.getItem("storeId"));
      
      this.formData.formData = formData;

      try {
        const { data } = await uploadFile(formData);
        if (data.state === true) {
          this.formData.formData = data.data ? data.data : this.formData
          this.$message.success(this.$t("fontConfig.uploadSuccess"));
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    open(data) {
      this.dialogVisible = true;
      if(data)  {
        this.title = this.$t("fontConfig.Edit Font");
        this.formData.fileName = data.fontFileName;
        this.formData.fontName = data.fontName;

        this.formData.formData = data;
      } else {
        this.title = this.$t("fontConfig.Create Font")
        this.formData = {
          fileName: '',
          fontName: ''
        }
        
      }
    },
    close() {
      this.$refs['form'].clearValidate();
      this.dialogVisible = false;
    },
    // 点击保存
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("doSubmit", this.formData);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.rule-dialog {
  .width_350 {
    width: 350px;
  }
  .form-box {
    margin: 30px 0;
  }
  .form-btn-section {
    width: 100%;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    // border-top: 1px solid #e5e5e5;
    ::v-deep .el-button {
      width: 110px;
    }
  }

  // 重置样式
  ::v-deep .el-dialog__body {
    padding: 20px 0;
    padding-right: 30px;
  }
  ::v-deep .el-checkbox {
    display: block;
    width: 50%;
    padding: 20px 0px;
    box-sizing: border-box;
    margin: 0;
    text-align: left;
  }
}

.upload-box ::v-deep {
  display: flex;
  .el-upload {
    width: 100%;
  }
  .upload-demo {
    width: 300px;
  }

  .el-button {
    width: 100%;
    border: 1px solid #dddddd;
    background: linear-gradient(0deg, #f4f4f4 0%, #ffffff 100%);
    border-radius: 6px;
    color: #666666;
    text-align: left;
    height: 40px;
  }
}
</style>
