<template>
  <div class="table-content">
    <el-dialog
      v-model="dialogVisible"
      :title="title"
      :before-close="close"
      :visible.sync="dialogVisible"
      width="1000px"
      :close-on-click-modal="false"
    >
        <el-table
            border
            :data="tableData"
            style="width: 100%"
            v-loading="tableLoading"
            @sort-change="handleSort"
        >
            <!-- <el-table-column align="center" type="selection">
                <el-table-column
                    align="center"
                    width="55"
                    type="selection"
                    style="display: none"
                >
                </el-table-column>
            </el-table-column>
            <el-table-column align="center" width="55" type="index" :label="$t('devices.esl.No')">
                <el-table-column type="index" align="center" width="55"> </el-table-column>
            </el-table-column> -->

            <el-table-column
                align="center"
                min-width="180"
                prop="user_name"
                :label="$t('fontConfig.customName')"
                sortable="custom"
            >
                <el-table-column min-width="180" align="center" prop="userName">
                    <template slot="header" slot-scope="scope">
                        <el-input
                            v-model="pageParams.userName"
                            @input="getList"
                            size="small"
                            placeholder=""
                            clearable
                        />
                    </template> 
                </el-table-column
            ></el-table-column>
            <el-table-column
                align="center"
                min-width="180"
                prop="login_id"
                :label="$t('fontConfig.customCode')"
                sortable="custom"
            >
                <el-table-column min-width="180" align="center" prop="customerCode">
                    <template slot="header" slot-scope="scope">
                        <el-input
                            v-model="pageParams.loginId"
                            @input="getList"
                            size="small"
                            placeholder=""
                            clearable
                        />
                    </template> 
                </el-table-column
            ></el-table-column>
            <el-table-column
                prop="update_time"
                :label="$t('fontConfig.updateTime')"
                min-width="180"
                header-align="center"
                sortable="custom"
            >
                <el-table-column prop="updateTime" min-width="180" align="center">
                    <template slot="header" slot-scope="scope">
                        <el-date-picker
                          v-model="pageParams.date"
                          type="daterange"
                          range-separator="-"
                          start-placeholder=""
                          end-placeholder=""
                          size="small"
                          @change="handleDateChange"
                          placement="bottom-start"
                          style="width: 90%"
                          clearable
                        />
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column
                :label="$t('fontConfig.fontPeimiss')"
                min-width="200"
                header-align="center"
            >
            <el-table-column align="center" min-width="200">
                <template slot="header"></template>
                <template slot="default" slot-scope="{ row }">
                    <b class="operate" @click="handelOperate(row)">
                        ...
                    </b>
                </template>
            </el-table-column>
            </el-table-column>
        </el-table>
        <pagination :tableInfo="pageParams" @changePage="changePage" />

        <FontPermissions ref="FontPermissions" @doSubmit="doSubmit"></FontPermissions>

    </el-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { debounce } from "lodash";
import { getFontUserList, bulidUserFontPower } from "@/libs/api/system-config-font";

import pagination from "@/components/pagination";
import FontPermissions from "./FontPermissions";
export default {
  components: {
    pagination,
    FontPermissions
  },
  props: {},
  name: "CustomerListDilog",
  data() {
    return {
      dialogVisible: false,
      title: this.$t("fontConfig.fontPeimiss"),
      moment,
      pageParams: {
        current: 1,
        size: 10,
        total: 0,

        userName: "",
        loginId: "",
        orders: [],
        // updateTime: ''
        date: [],
      },
      tableData: [],
      tableLoading: false,

      currentItem: {},
    };
  },
  methods: {
    handleDateChange(val) {
      if(val) {
        let date = [moment(val[0]).format("YYYY-MM-DD"), moment(val[1]).format("YYYY-MM-DD")];
        this.pageParams.updateTime = date.join(',');
      } else {
        this.pageParams.updateTime = ''
      }
      this.getList();
    },
    async doSubmit(data) {
      const params = {
       selectedUserLoginId: this.currentItem.loginId,
       selectedIds: data.join(',')
      };
      try {
        const { data } = await bulidUserFontPower(params);
        if (data.state === true) {
          this.$message.success(this.$t('fontConfig.operationSuccess'));
          this.$refs.FontPermissions.dialogVisible = false;
          // this.getList();
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    handelOperate(row) {
      this.currentItem = row;
      this.$refs.FontPermissions.open(row);
    },
    open(data) {
      this.dialogVisible = true;
      this.getList();
    },
    close() {
      this.dialogVisible = false;
    },
     // 表格排序
    handleSort(column, prop, order){
      console.log('表格排序',column, prop, order)
      let arr = []
      if(column.order){
        let str1= column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':'+ column.prop;
        arr.push(str1)
      } else {
        arr = []
      }
      
      this.pageParams.orders = arr;
      this.getList()
    },
    changePage() {
      this.getList();
    },
    getList: debounce(async function () {
      const _this = this;
      const params = {
        userName: _this.pageParams.userName,
        userLoginId: _this.pageParams.loginId,
        updateTime:  _this.pageParams.updateTime,

        current: _this.pageParams.current,
        size: _this.pageParams.size,
        orders: _this.pageParams.orders,
      };
      _this.tableLoading = true;
      try {
        const { data } = await getFontUserList(params);
        if (data.state === true) {
          let dataNew = data.data || {};
          _this.tableData = dataNew.records || [];
          _this.pageParams.total = dataNew.total || 0;
          _this.tableLoading = false;
        } else {
          this.$message.error(data.message);
          _this.tableLoading = false;
        }
      } catch (err) {
        const { message } = err;
        _this.$message.error(message);
        _this.tableLoading = false;
      }
    }, 1000)
  },
  created() {
    
  },
};
</script>
<style lang="scss" scoped>

.table-content ::v-deep {
  .operate {
    cursor: pointer;
    height: 30px;
    display: inline-block;
  }
}
</style>