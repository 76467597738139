<template>
  <div class="font-permissions-table-content">
    <el-dialog
      v-model="dialogVisible"
      :title="title"
      :before-close="close"
      :visible.sync="dialogVisible"
      width="600px"
      :close-on-click-modal="false"
      :append-to-body="true"
      style="text-align: center;"
      v-if="dialogVisible"
    >
        <el-table
            class="table"
            border
            :data="tableData"
            style="width: 100%"
            v-loading="tableLoading"
            @selection-change="handleSelectionChange"
            @sort-change="handleSort"
            ref="table"
            :row-key="(row)=>{ return row.fontId }"
        >
            <el-table-column  align="center" type="selection">
                <!-- <el-table-column
                  align="center"
                  width="55"
                  type="selection"
                  style="display: none"
                >
                </el-table-column> -->
            </el-table-column>

            <el-table-column
                align="center"
                min-width="180"
                prop="fontName"
                :label="$t('fontConfig.font Name')"
            >
                <!-- <el-table-column min-width="180" align="center" prop="fontName">
                    <template slot="header" slot-scope="scope">
                        <el-input
                            v-model="pageParams.fontName"
                            @input="getList"
                            size="small"
                            placeholder=""
                            clearable
                        />
                    </template> 
                </el-table-column> -->
              </el-table-column>
        </el-table>

        <el-button 
          color="#28C76F"
          size="small"
          type="success"
          @click="onSubmit"
            style="margin-top: 20px;"
        >{{
          $t("button.Save")
        }}</el-button>

        <!-- <pagination :tableInfo="pageParams" @changePage="changePage" /> -->

    </el-dialog>
  </div>
</template>
<script>
import { debounce } from "lodash";
import { getFontList,  getFontListByPower } from "@/libs/api/system-config-font";
import pagination from "@/components/pagination";
import FontPermissions from "./FontPermissions";
export default {
  components: {
    pagination,
    FontPermissions
  },
  props: {},
  name: "CustomerListDilog",
  data() {
    return {
      dialogVisible: false,
      title: this.$t("fontConfig.fontPeimiss"),
      pageParams: {
        current: 1,
        size: 100,
        total: 0,

        fontName: "",
        orders: [],
      },
      tableData: [],
      tableLoading: false,

      ids: [],   
    };
  },
  methods: {
    onSubmit() {
        if(!this.ids.length) {
            this.$message.error(this.$t('fontConfig.Select at least one'))
            return;
        }
        this.$emit('doSubmit', this.ids);
    },
    open(data) {
      this.dialogVisible = true;
      this.getList(data);
    },
    close() {
      this.dialogVisible = false;
    },
     // 表格排序
    handleSort(column, prop, order){
      console.log('表格排序',column, prop, order)
      let arr = []
      if(column.order){
        let str1= column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':'+ column.prop;
        arr.push(str1)
      } else {
        arr = []
      }
      
      this.pageParams.orders = arr;
      this.getList()
    },
    changePage() {
      this.getList();
    },
    getList: debounce(async function (data) {
      let _this = this;
      // let userData = JSON.parse(localStorage.getItem("userData"));
      let { fontName, orders, size, current } = this.pageParams;
      const params = {
        // storeId: userData.storeId,
        fontName,
        orders,
        size,
        current,
        selectedUserLoginId: data.loginId,
      };
      _this.tableLoading = true;
    
      try {
        const { data } = await getFontListByPower(params);
        if (data.state === true) {
          let dataNew = data.data || {};
          _this.tableData = dataNew.allList || [];

          
          if(this.$refs && this.$refs.table) {
            this.$refs.table.clearSelection();  //清空未保存勾选
          }

          let allList = dataNew.allList || [];
          let selectedList = dataNew.selectedList || [];
          let ids = [];
          this.ids = ids;
          //回显已选中的字体
          setTimeout(()=> {
            _this.$nextTick(() => {
              selectedList.forEach((item)=> {
                allList.forEach((row)=> {
                  if(item.fontFileName === row.fontFileName) {
                    ids.push(row.fontId);
                    this.ids = ids;
                    if(_this.$refs && this.$refs.table) {
                      _this.$refs.table.toggleRowSelection(row);
                    }
                    // _this.$refs.table.toggleRowSelection(row);
                  }
                })
              })
            })
          }, 1200)
          _this.pageParams.total = dataNew.total || 0;
          _this.tableLoading = false;
        } else {
          this.$message.error(data.message);
          _this.tableLoading = false;
        }
      } catch (err) {
        const { message } = err;
        _this.$message.error(message);
        _this.tableLoading = false;
      }
    }, 1000),
    
    handleSelectionChange(data) {
      let ids = [];
      data.forEach((item) => {
        ids.push(item.fontId);
      });
      this.ids = ids;
    },
  },
  created() {
    
  },
};
</script>
<style lang="scss" scoped>
.table ::v-deep {
    .el-table__header-wrapper
    .el-table__header
    .is-group
    tr:nth-of-type(2)
    th:nth-of-type(1)
    .cell {
    // display: none !important;
  }
}
</style>