import router from "../axios";

import config from "../../config/index"
const { baseUrl } = config;

// 获取字体列表
function getFontList(data) {
  return router({
    url: baseUrl+"template/v1/getFontList",
    data:data,
    method: "POST",
  });
}
// 创建字体  
function createFont(data) {
    return router({
      url: baseUrl+"template/v1/createFont",
      data:data,
      method: "POST",
    });
  }
  // 更新字体  
function updateFont(data) {
  return router({
    url: baseUrl+"template/v1/updateFont",
    data:data,
    method: "POST",
  });
}
  // 删除字体
function deleteFont(data) {
    return router({
      url: baseUrl+"template/v1/deleteFont",
      data:data,
      method: "POST",
    });
  }
  // 文件上传
  function uploadFile(data) {
    return router({
      // url: baseUrl+"file/v1/upload/file",
      url: baseUrl+"file/v1/upload/fontFile",
      data: data,
      method: "POST",
    });
  }
  // 
  function getFontListByPage(data) {
    return router({
      url: baseUrl+"template/v1/getFontListByPage",
      data: data,
      method: "POST",
    });
  }
  
  
  function getFontUserList(data) {
    return router({
      url: baseUrl+"template/v1/getFontUserList",
      data: data,
      method: "POST",
    });
  }
  
  function getFontListByPower(data) {
    return router({
      url: baseUrl+"template/v1/getFontListByPower",
      data: data,
      method: "POST",
    });
  }

  function bulidUserFontPower(data) {
    return router({
      url: baseUrl+"template/v1/bulidUserFontPower",
      data: data,
      method: "POST",
    });
  }

export {
  getFontList,
  createFont,
  deleteFont,
  uploadFile,
  getFontListByPage,
  getFontUserList,
  getFontListByPower,
  updateFont,
  bulidUserFontPower
}